.orderLogStatusField{
  width: 10%;
  padding-left: 24px !important;
}

.orderIDField{
  width: 10%;
}

.imageURLField{
  width: 2%;
}

.recipeIDField{
  width: 8%;
}

.recipeNamField{
  width: 20%;
}

.storeNameField{
  width: 27%;
}

.machineSerialField{
  width: 8%;
}

.orderReceivedAtField{
  width: 15%;
}