.recipeCategoryDisplayNameField{
  width: 14%;
  padding-left: 24px !important;
}

.recipeCategoryNameField{
  width: 20%;
}

.recipeCategoryIDField{
  width: 12%;
}

.recipeCategoryBlenderPresetField{
  width: 50%;
}

.recipeCategoryIconButtonsField{
  width: 4%;
}
