.base {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    border-radius: 40px;
  }
  
  .general {
    background: #EBF2EF;
    color: #005739;
  
  }
  
  .tevana {
    background: #FFF8EE;
    color: #F9A825;
  
  }
  
  .myfruits {
    background: #FBEEEE;
    color: #C82420;
  }

  .others {
    background: #919191;
    color: #000000;
  }
  