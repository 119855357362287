.sidebar-item {
  height: 48px;
}

@media (max-width: 820px) {
  .hide-sidebar {
    display: none !important;
  }
}

@media (min-width: 821px) {
  .hide-appbar {
    display: none !important;
  }
}