.base {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  border-radius: 40px;
  margin-left: 10px;
}

.not_is_approved {
  background: #FBEEEE;
  color: #C82420;
}

.is_approved {
  background: #EBF2EF;
  color: #005739;
}
