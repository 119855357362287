.offerGenreShortField {
  width: 10%;
  padding-left: 24px !important;
}

.offerGenreWideField {
  width: 75%;
}

.offerGenreIconButtonField {
  width: 5%;
}
