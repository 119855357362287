@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);

*, *::before, *::after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  letter-spacing: 0.05em;
  background-color: #212121;
  overflow-x: hidden;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

.MuiContainer-root::-webkit-scrollbar {
  display: none;
}

.MuiBox-root::-webkit-scrollbar {
  display: none;
}

button {
  letter-spacing: 0.05em;
}

@media(prefers-reduced-motion:reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *, *::before, *::after {
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: .01ms !important;
    scroll-behavior: auto !important;
  }
}
