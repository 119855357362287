.userIdField{
  width: 10%;
  padding-left: 24px !important;
}

.userNameField{
  width: 30%;
}

.userDepartmentField{
  width: 25%;
}

.userAuthGroupField{
  width: 5%;
}

.userCreatedAtField{
  width: 5%;
}

.userLoggedInAtField{
  width: 15%;
}

.userMoreButtonField{
  width: 10%;
}
