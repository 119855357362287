// ListToolbarのスタイルをsxでオーバライドできなかったため、CSS Modulesを使って上書きする
.toolbar {
  display: block !important;
  min-height: 48px !important;
  border-bottom: 1px solid #EDEAE3;

  & > form{
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    padding:0px;
    height: 100%;

    & > div {
      display: block !important;

      &:not(:last-child) {
        flex-grow: 1;
      }

      &:last-child {
        width: 0px;
      }

      // 子要素側で指定できなかったため、コンポーネント外の親要素で上書きする
      &[data-source=offerStatus] {
        flex-grow: 0;
        width: 85%;
        border-right: 1px solid #EDEAE3;
      }

      &[data-source=recipeCategoryID] {
        flex-grow: 0;
        width: 15%;
      }

      &[data-source=status] {
        flex-grow: 0;
        width: 45%;
      }

      &[data-source=machineSerial] {
        flex-grow: 0;
        display: flex;
        min-width: 17.5%;
      }

      &[data-source=storeName] {
        flex-grow: 0;
        min-width: 17.5%;
      }

      &[data-source=orderReceivedAtStart] {
        flex-grow: 0;
        width: 15%;
      }

      &[data-source=orderReceivedAtEnd] {
        flex-grow: 0;
        width: 15%;
      }

      &[data-source=latestApprovedAtStart] {
        flex-grow: 0;
        width: 22.5%;
      }

      &[data-source=latestApprovedAtEnd] {
        flex-grow: 0;
        width: 11.5%;
      }

      &[data-source=operationStatus] {
        flex-grow: 0;
        width: 70%;
      }

    }

    & fieldset {
      border-radius: 0;
      border: none;
    }

  }
}

table {
  tr:last-child {
    td {
      border-bottom: none!important;
    }
  }
}

// 要素が空の場合にreact-adminのEmptyコンポーネントを描画している
// ダッシュボード全体のテーマで#fffとしているので、#000で上書きする
.empty {
  color: #000;
}
