.recipeOfferGenreField{
  width: 8%;
  padding-left: 24px !important;
}

.recipeCategoryField{
  width: 15%;
}

.recipeImageField{
  width: 5%;
}

.recipeIdField{
  width: 10%;
}

.recipeNameField{
  width: 20%;
}

.recipeOfferStartAtField{
  width: 15%;
}

.recipeOfferEndAtField{
  width: 15%;
}

.recipeIconButtonsField{
  width: 12%;
}
