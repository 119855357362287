.utensilIdField{
    width: 15%;
    padding-left: 24px !important;
  }

  .utensilNameField{
    width: 30%;
  }

  .utensilEjectionAmountField{
    width: 10%;
  }

  .utensilEjectionTypeField{
    width: 20%;
  }
  .utensilMoreButtonField{
    width: 5%;
  }
