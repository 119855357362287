.storeTypeField{
  width: 12%;
  padding-left: 24px !important;
}

.storeNameField{
  width: 40%;
}

.storeIdField{
  width: 8%;
}

.storeTelField{
  width: 12%;
}

.storeManagerField{
  width: 10%;
}

.userMoreButtonField{
  width: 18%;
}
