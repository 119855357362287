.storeTypeField {
  width: 15%;
  padding-left: 24px !important;
}

.placementPatternField {
    width: 60%;
  }

.dateAndFunctionField {
    width: 5%
  }

.iconButtonsFieldMaxWidth {
    width: 15%;
}

.iconButtonsFieldMinWidth {
  width: 10%;
}
