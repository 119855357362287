.ingredientIDField{
    width: 7%;
    padding-left: 24px !important;
}

.ingredientCategoryNameField{
    width: 12%;
}

.ingredientImageField{
    width: 8%;
}

.ingredientNameField{
    width: 45%;
}

.ingredientOfferDateField{
    width: 15%;
}

.ingredientMoreButtonField{
    width: 15%;
}
